import moment from 'moment';
import {
    GenericCargo,
    Milestones,
    Location,
    GenericShipment,
    TemperatureCheckResult,
    EnergyLevelCheckResult,
} from 'dataTypes/SecureBackend/apiResponse/Shipment';
import { Geolocation, Airport, GenericPackaging, Packaging } from 'dataTypes/SecureBackend/apiResponse';
import {
    LatLng,
    PolylineAndStepStatus,
    TimeRange,
} from 'dataTypes/common';
import { CheckboxOption } from 'shared-components/dataTypes';
import icons from 'shared-components/icons';
import {
    APPROVAL_STATUS,
    ORDER_STEP_TYPE,
    TEMPERATURE_STATUS,
    assetsPath,
} from 'shared-components/constants';

export interface ActivePackaging {
    serialNumber: string,
    timestamp: number,
}

export interface ProcessedPackagingData {
    currentShipment: string,
    customerReference: string,
    lastMeasuredGeolocationTimestamp: number,
    lastMeasuredGeolocationTimestampUnit?: {
        unit: string,
        value: number
    }
    lastMeasuredLatitude: number,
    lastMeasuredLongitude: number,
    lastMeasuredTempAmbient: number,
    lastMeasuredTempAmbientTimestamp: number,
    lastMeasuredTempAmbientTimestampUnit?: {
        unit: string,
        value: number
    }
    lastMeasuredTempInternal: number,
    lastMeasuredTempInternalTimestamp: number,
    lastMeasuredTempInternalTimestampUnit?: {
        unit: string,
        value: number
    }
    packagingPicture: string,
    serialNumber: string,
    temperatureRange: string,
    type: string
}

export interface GenericProcessedPackagingData {
    jypId: string,
    packagingTypeCode: string,
    serialNumber: string
}

export const fetchPackaging = (
    rawData: Packaging, packagingCodeLabels = {}, notInShipment = null,
): ProcessedPackagingData => {
    const {
        currentShipment = null,
        lastMeasurementInfo,
        packagingType,
        serialNumber = '',
    } = rawData;

    const {
        geolocation: lastMeasuredGeolocation = null,
        geolocationTimestamp: lastMeasuredGeolocationTimestamp = null,
        tempAmbient: lastMeasuredTempAmbient,
        tempAmbientTimestamp: lastMeasuredTempAmbientTimestamp,
        tempInternal: lastMeasuredTempInternal,
        tempInternalTimestamp: lastMeasuredTempInternalTimestamp,
    } = lastMeasurementInfo || {};

    const lastMeasuredLatitude = lastMeasuredGeolocation && lastMeasuredGeolocation?.latitude
        ? (Math.trunc(lastMeasuredGeolocation?.latitude * 10000) / 10000)
        : null;

    const lastMeasuredLongitude = lastMeasuredGeolocation && lastMeasuredGeolocation?.longitude
        ? (Math.trunc(lastMeasuredGeolocation?.longitude * 10000) / 10000)
        : null;

    const {
        packagingPictureUrl: pictureUrl = null,
        temperatureRange = '',
        type = '',
    } = packagingType;

    return {
        currentShipment: currentShipment || notInShipment,
        customerReference: '',
        lastMeasuredGeolocationTimestamp: new Date(lastMeasuredGeolocationTimestamp).getTime(),
        lastMeasuredLatitude,
        lastMeasuredLongitude,
        lastMeasuredTempAmbient,
        lastMeasuredTempAmbientTimestamp: lastMeasuredTempAmbientTimestamp
            ? moment(lastMeasuredTempAmbientTimestamp).utc(true).valueOf()
            : null,
        lastMeasuredTempInternal,
        lastMeasuredTempInternalTimestamp: lastMeasuredTempInternalTimestamp
            ? moment(lastMeasuredTempInternalTimestamp).utc(true).valueOf()
            : null,
        packagingPicture: pictureUrl ? `${assetsPath}/assets${pictureUrl}` : icons.default_packaging_icon,
        serialNumber,
        temperatureRange,
        type: packagingCodeLabels[type] || type,
    };
};

export const fetchGenericPackaging = (
    rawData: GenericPackaging,
): GenericProcessedPackagingData => {
    const {
        jypId = '',
        packagingTypeCode = '',
        serialNumber = '',
    } = rawData;

    return {
        jypId,
        packagingTypeCode,
        serialNumber,
    };
};

export const fetchPackagingData = (rawData: Packaging[], packagingCodeLabels = {}, notInShipment = null)
: ProcessedPackagingData[] => {
    return rawData.map(item => fetchPackaging(item, packagingCodeLabels, notInShipment));
};

export const ringMarkerIconObj = {
    anchor: { x: 5, y: 5 },
    origin: { x: 0, y: 0 },
    scaledSize: { height: 10, width: 10 },
    url: icons.polylinePathOrangeRingIcon,
};

export const crossMarkerIconObj = {
    anchor: { x: 10, y: 10 },
    origin: { x: 0, y: 0 },
    scaledSize: { height: 20, width: 20 },
    url: icons.hex_with_cross,
};

export const filterByPackagingShipmentStatus = (options: string[], currentShipment): boolean => {
    return (options.includes('IN_SHIPMENT') && currentShipment !== null)
        || (options.includes('NOT_IN_SHIPMENT') && currentShipment === null);
};

export const getMapDataItem = (packaging: ProcessedPackagingData) => {
    return {
        geometry: {
            coordinates: [
                packaging.lastMeasuredLongitude,
                packaging.lastMeasuredLatitude,
            ],
            type: 'Point',
        },
        properties: {
            data: packaging,
            marker: true,
        },
        type: 'Feature',
    };
};

export const getSuperclusterOptions = (mapData, bounds, zoom = 10) => {
    return {
        bounds,
        options: {
            generateId: true,
            map: (item) => {
                return {
                    lastMeasuredTempInternalTimestamp: item.data.lastMeasuredTempInternalTimestamp,
                    type: item.data.type,
                };
            },
            maxZoom: 5,
            radius: 75,
            reduce: (data, props) => {
                const { lastMeasuredTimes = [], packagingTypes = [] } = data;

                if (!packagingTypes.includes(props.type)) {
                    data.packagingTypes = [...packagingTypes, props.type];
                }

                if (!lastMeasuredTimes.includes(props.lastMeasuredTempInternalTimestamp)) {
                    data.lastMeasuredTimes = [...lastMeasuredTimes, props.lastMeasuredTempInternalTimestamp];
                }
                return data;
            },
        },
        points: mapData,
        zoom,
    };
};

export const emptyFunction = () => {};

export const getCombinedShipmentPolylines = (
    shipmentPolylines: PolylineAndStepStatus[] = [],
    polylinesFromSensorData: LatLng[] = [],
    currentMarkerPosition: LatLng,
): PolylineAndStepStatus[] => {
    if (polylinesFromSensorData.length === 0) {
        return shipmentPolylines;
    }
    const packagingTraveledPath: PolylineAndStepStatus[] = polylinesFromSensorData.length !== 0 ? [{
        path: [
            ...polylinesFromSensorData,
            currentMarkerPosition,
        ],
        stepStatus: 'CLOSED',
    }] : [];

    const plannedShipmentPath = shipmentPolylines.filter(item => item.stepStatus !== 'CLOSED');

    if (plannedShipmentPath.length > 0) {
        const [firstShipmentPolylineLocation] = plannedShipmentPath[0].path;

        const shipmentPolylinePath: PolylineAndStepStatus[] = [
            ...packagingTraveledPath,
            {
                path: [
                    currentMarkerPosition,
                    firstShipmentPolylineLocation,
                ],
                stepStatus: 'NOT_STARTED',
            },
            ...plannedShipmentPath,
        ];

        return shipmentPolylinePath;
    }

    return packagingTraveledPath;
};

const isSamePositions = (first: LatLng, second: LatLng): boolean => {
    return first.lat === second.lat && first.lng === second.lng;
};

export const getPolylineMarkers = (polyline: PolylineAndStepStatus[], currentMarkerPosition: LatLng) => {
    return polyline.reduce((data, item, index) => {
        const { path } = item;

        if (path.length === 0) {
            return data;
        }
        const [firstPosition] = path;
        const lastPosition = path[path.length - 1];

        if (index === 0) {
            return [
                ...(isSamePositions(firstPosition, currentMarkerPosition) ? [] : [firstPosition]),
                ...(isSamePositions(lastPosition, currentMarkerPosition) ? [] : [lastPosition]),
            ];
        } else if (isSamePositions(lastPosition, currentMarkerPosition)) {
            return [
                ...data,
                lastPosition,
            ];
        }

        return data;
    }, []);
};

export const getApprovalStatuses = (cargo: GenericCargo[]): string[] => {
    return cargo.map(item => {
        const { skyCoreProductRelease } = item;
        const { approvalStatus = APPROVAL_STATUS.CHECK_NOT_PASSED } = skyCoreProductRelease || {};

        return approvalStatus;
    });
};
export const getEnergyLevels = (cargo: GenericCargo[]): EnergyLevelCheckResult[] => {
    return cargo.map(item => item.energyLevelCheckResult);
};
export const getTemperatureStatuses = (cargo: GenericCargo[]): string[] => {
    return cargo.map(item => {
        const { temperatureCheckResult } = item;
        const { temperatureStatus = TEMPERATURE_STATUS.EXCURSION } = temperatureCheckResult || {};

        return temperatureStatus;
    });
};

const getNumberOfStatus = (statuses: string[], requiredStatus: string): number => (
    statuses.filter((status) => status === requiredStatus).length
);

export const getPackagingIcons = (statuses: string[]): string[] => {
    if (statuses.length < 6) {
        return statuses.map((status) => {
            if (status === APPROVAL_STATUS.REJECTED
                || status === APPROVAL_STATUS.CHECK_NOT_PASSED) {
                return icons.rectangle_red;
            }
            if (status === APPROVAL_STATUS.NOT_CHECKED) {
                return icons.rectangle_grey;
            }
            return icons.rectangle_blue;
        });
    }
    const notApprovedCount = getNumberOfStatus(statuses, APPROVAL_STATUS.REJECTED);

    /** Show ceiling(4 x count(ProductRelease | REJECTED) / count(ProductRelease))
     dots with color #D44848 and the other dots with #61C6E9 */
    const redIconsCount = Math.ceil((4 * notApprovedCount) / statuses.length);

    return [
        redIconsCount > 0 ? icons.rectangle_red : icons.rectangle_blue,
        redIconsCount > 1 ? icons.rectangle_red : icons.rectangle_blue,
        icons.dots_blue,
        redIconsCount > 2 ? icons.rectangle_red : icons.rectangle_blue,
        redIconsCount > 3 ? icons.rectangle_red : icons.rectangle_blue,
    ];
};

export const checkIsWarning = (statuses: string[]): boolean => (
    statuses.includes(APPROVAL_STATUS.REJECTED)
    || statuses.includes(APPROVAL_STATUS.CHECK_NOT_PASSED)
);

export const checkIsPending = (statuses: string[]): boolean => {
    const approvedCount = getNumberOfStatus(statuses, APPROVAL_STATUS.APPROVED);

    const checkPassedCount = getNumberOfStatus(statuses, APPROVAL_STATUS.CHECK_PASSED);

    return (statuses.length !== 0
        && (checkPassedCount === statuses.length
            || ((checkPassedCount + approvedCount) === statuses.length && checkPassedCount > 0)
        )
    );
};

export const checkIsAllApproved = (statuses: string[]): boolean => {
    const approvedCount = getNumberOfStatus(statuses, APPROVAL_STATUS.APPROVED);

    return (statuses.length !== 0 && approvedCount === statuses.length);
};

export const getPackagingTypesCount = (cargo: GenericCargo[]): { [key: string]: number } => {
    return cargo.reduce((types, item) => {
        const { packaging = null } = item || {};
        const { packagingTypeCode = null } = packaging || {};

        return packagingTypeCode
            ? { ...types, [packagingTypeCode]: types[packagingTypeCode] ? types[packagingTypeCode] + 1 : 1 }
            : types;
    }, {});
};

export interface ShipmentWithSharedField extends GenericShipment {
    isShared: boolean,
}

export interface ProcessedPackagingAndProductRelease {
    productRelease?: string,
    temperatureCheckResult: TemperatureCheckResult
}

export interface GenericShipmentData {
    cargo?: GenericCargo[];
    currentPosition: LatLng,
    customerReference: string,
    destinationAirport: string,
    externalId: string,
    forwarderId?: number,
    hawbNumber: string,
    id?: number | string,
    isAllApproved: boolean,
    isBreach: boolean,
    isPending: boolean,
    isPotentialWarning: boolean,
    isWarning: boolean,
    leaseEnd?: string,
    leaseStart?: string,
    marker: LatLng,
    mawbNumber: string,
    originAirport: string,
    packagings: ProcessedPackagingAndProductRelease[],
    packagingSquareIcons: string[],
    packagingsSerialNumberList?: string[],
    packagingTypesCount: { [key: string]: number },
    pharmaCompanyName: string,
    polylines: PolylineAndStepStatus[],
    shipmentEnd?: string,
    shipmentSensorDataTimeRange: TimeRange,
    shipmentStart?: string,
    status: string,
    statusLabel?: string,
    temperatureRange: string
}

export type GenericShipmentDTO = {
    resultList: ShipmentWithSharedField[],
    totalElements: number,
}

export const statuses = {
    CLOSED: 'Closed',
    IN_TRANSIT: 'In Transit',
    NOT_STARTED: 'Not Started',
};

const isNumber = (value: any): boolean => {
    return !Number.isNaN(Number.parseFloat(value));
};

const isPathCorrect = (startLocation: (Location), endLocation: (Location)): boolean => {
    const { geolocation: startGeolocation = null } = startLocation || {};
    const { geolocation: endGeolocation = null } = endLocation || {};

    return startGeolocation !== null && endGeolocation !== null
        && isNumber(startGeolocation?.latitude) && isNumber(startGeolocation?.longitude)
        && isNumber(endGeolocation?.latitude) && isNumber(endGeolocation?.longitude);
};

const getLocation = (location: Geolocation): LatLng => ({
    lat: (Math.trunc(location?.latitude * 10000) / 10000),
    lng: (Math.trunc(location?.longitude * 10000) / 10000),
});

const getPolyline = (startPoint: (Airport | Location), endPoint: (Airport | Location), stepStatus?: string)
    : PolylineAndStepStatus => (
    {
        path: [getLocation(startPoint?.geolocation), getLocation(endPoint?.geolocation)],
        stepStatus,
    }
);

export const getMarker = (polylines: PolylineAndStepStatus[]): LatLng => {
    if (!polylines?.length) {
        return null;
    }

    if (polylines[0].stepStatus !== ORDER_STEP_TYPE.CLOSED) {
        return polylines[0].path[0];
    }

    for (let i = 1; i < polylines.length; i++) {
        if (polylines[i - 1].stepStatus === ORDER_STEP_TYPE.CLOSED
            && polylines[i].stepStatus !== ORDER_STEP_TYPE.CLOSED) {
            return polylines[i].path[0];
        }
    }

    return polylines[polylines.length - 1].path[1];
};

export const getPolylines = (milestones: Milestones[] = []): PolylineAndStepStatus[] => {
    return milestones?.reduce((polylines, milestones: Milestones) => {
        const {
            deliveryLocation = null,
            pickupLocation = null,
            progress = null,
        } = milestones;

        if (isPathCorrect(pickupLocation, deliveryLocation)) {
            return [
                ...polylines,
                getPolyline(pickupLocation, deliveryLocation, progress.status),
                getPolyline(pickupLocation, deliveryLocation, progress.status),
            ];
        } else {
            return [
                ...polylines,
                getPolyline(pickupLocation, deliveryLocation, progress.status),
            ];
        }
    }, []);
};
export const getCurrentPosition = (milestones: Milestones[] = []): LatLng => {
    let currentPosition = null;

    if (milestones.find(it => it.progress.status === ORDER_STEP_TYPE.IN_PROGRESS)) {
        const intersectingStep = milestones.find(it => it.progress.status === ORDER_STEP_TYPE.IN_PROGRESS);

        currentPosition = intersectingStep?.pickupLocation || null;
    } else if (milestones.find(it => (it.progress.status === ORDER_STEP_TYPE.CLOSED
        || it.progress.status === ORDER_STEP_TYPE.COMPLETED))) {
        const [intersectingStep] = milestones.filter(it => (it.progress.status === ORDER_STEP_TYPE.CLOSED
            || it.progress.status === ORDER_STEP_TYPE.COMPLETED)).slice(-1);

        currentPosition = intersectingStep?.deliveryLocation || null;
    } else {
        const [intersectingStep] = milestones;

        currentPosition = intersectingStep?.pickupLocation || null;
    }

    if (!currentPosition) return null;
    const {
        latitude: lat,
        longitude: lng,
    } = currentPosition?.geolocation || {};

    return lng && lat ? {
        lat,
        lng,
    } : null;
};

export const getGenericApprovalStatuses = (shipment: GenericShipment): string => {
    return shipment.status;
};
export const getShipmentTimeRange = (
    shipmentStart: string,
    shipmentEnd: string,
): TimeRange => {
    const from = shipmentStart
        ? moment(shipmentStart).utc().format('YYYY-MM-DDTHH:mm')
        : null;

    const to = shipmentEnd !== null
        ? shipmentEnd
        : moment().utc().format('YYYY-MM-DDTHH:mm');

    return { from, to };
};

export const getShipmentData = (
    rawData: ShipmentWithSharedField[] = [],
): GenericShipmentData[] => {
    const processedData = rawData.map(shipment => {
        const {
            cargo = [],
            customerReference = '',
            customerSpecificInformation = null,
            destinationAirport = '',
            externalId,
            forwarderId = null,
            hawbNumber = '',
            id,
            mawbNumber = '',
            milestones: rawMilestones = null,
            originAirport = '',
            pharmaCompany = null,
            shipmentEnd = null,
            shipmentStart = null,
            status = '',
            temperatureRange = '',
        } = shipment;

        const {
            name: pharmaCompanyName = '',
        } = pharmaCompany || {};

        const milestones = rawMilestones?.sort((a, b) => Number(a.index) - Number(b.index));

        const approvalStatuses = getApprovalStatuses(cargo);
        const temperatureStatuses = getTemperatureStatuses(cargo);
        const polylines = getPolylines(milestones);
        const currentPosition = getCurrentPosition(milestones);
        const packagings = (cargo);
        const energyLevel = getEnergyLevels(cargo);
        const energyLevelsList = energyLevel
            .map(item => ((item?.remainingEnergyLevel / 1) * 100).toFixed(0));

        return {
            cargo,
            currentPosition,
            customerReference,
            customerSpecificInformation,
            destinationAirport,
            energyLevelsList,
            externalId,
            forwarderId,
            hawbNumber,
            id,
            isAllApproved: checkIsAllApproved(approvalStatuses),
            isBreach: cargo.some(c => c.energyLevelCheckResult?.energyLevelStatus === 'ENERGY_LEVEL_BREACH'),
            isPending: checkIsPending(approvalStatuses),
            isPotentialWarning: temperatureStatuses.includes(TEMPERATURE_STATUS.POTENTIAL_EXCURSION)
                || temperatureStatuses.includes(TEMPERATURE_STATUS.PREDICTED_EXCURSION),
            isWarning: temperatureStatuses.includes(TEMPERATURE_STATUS.EXCURSION),
            marker: getMarker(polylines),
            mawbNumber,
            originAirport,
            originAirportCity: '',
            packagingSquareIcons: getPackagingIcons(approvalStatuses),
            packagingTypesCount: getPackagingTypesCount(cargo),
            packagings,
            pharmaCompanyName,
            polylines,
            shipmentEnd,
            shipmentSensorDataTimeRange: getShipmentTimeRange(
                shipmentStart,
                shipmentEnd,
            ),
            shipmentStart,
            status: statuses[status],
            temperatureRange,
        };
    });

    return processedData;
};

export interface AvailableFilterOptions {
    allPackagingCount: number,
    allShipmentCount: number,
    destinationAirports: string[],
    originAirports: string[],
    shipmentStatus: CheckboxOption[],
    temperatureStatus: CheckboxOption[]
}

export const initialAvailableFilterOptions: AvailableFilterOptions = {
    allPackagingCount: 0,
    allShipmentCount: 0,
    destinationAirports: [],
    originAirports: [],
    shipmentStatus: [],
    temperatureStatus: [],
};

const getFilterOptions = (shipments: GenericShipmentData[] = []): { [key: string]: string[] } => {
    return shipments.reduce((data, shipment) => {
        const {
            destinationAirportsArr = [],
            originAirportsArr = [],
            shipmentStatusArr = [],
            temperatureStatusArr = [],
        } = data;
        const {
            destinationAirport,
            originAirport,
            packagings = [],
            status = null,
        } = shipment;

        const temperatureStatuses = packagings.reduce((data, packaging) => {
            const { temperatureStatus = null } = packaging.temperatureCheckResult || {};

            return temperatureStatus
            && !data.includes(temperatureStatus)
            && !temperatureStatusArr.includes(temperatureStatus)
                ? [...data, temperatureStatus]
                : data;
        }, []);

        return {
            destinationAirportsArr: destinationAirport && !destinationAirportsArr.includes(destinationAirport)
                ? [...destinationAirportsArr, destinationAirport]
                : destinationAirportsArr,
            originAirportsArr: originAirport && !originAirportsArr.includes(originAirport)
                ? [...originAirportsArr, originAirport]
                : originAirportsArr,
            shipmentStatusArr: status && !shipmentStatusArr.includes(status)
                ? [...shipmentStatusArr, status]
                : shipmentStatusArr,
            temperatureStatusArr: temperatureStatusArr.concat(temperatureStatuses),
        };
    }, {
        destinationAirportsArr: [],
        originAirportsArr: [],
        shipmentStatusArr: [],
        temperatureStatusArr: [],
    });
};

const fetchShipmentStatusOptions = (
    shipments: GenericShipmentData[], availableStatuses: string[], labels = {}, descriptions = {},
): CheckboxOption[] => {
    return availableStatuses.map(option => ({
        count: shipments.filter(item => item.status === option).length,
        description: descriptions[option] || '',
        label: labels[option] || option,
        value: option,
    }));
};

const getPackagingCountByTemperatureStatus = (shipments: GenericShipmentData[], status: string): number => {
    return shipments.reduce((count, shipment) => {
        const { packagings = [] } = shipment;

        return packagings.length === 0
            ? count
            : count + packagings.filter(packaging => {
                const { temperatureStatus = null } = packaging.temperatureCheckResult || {};

                return temperatureStatus === status;
            }).length;
    }, 0);
};

const fetchTemperatureStatusOptions = (
    shipments: GenericShipmentData[], availableStatuses: string[], labels = {}, descriptions = {},
): CheckboxOption[] => {
    return availableStatuses.map(option => ({
        count: getPackagingCountByTemperatureStatus(shipments, option),
        description: descriptions[option] || '',
        label: labels[option] || option,
        value: option,
    }));
};

export const getAvailableFilterOptions = (
    shipments: GenericShipmentData[] = [],
    shipmentStatusLabels = {},
    temperatureStatusLabels = {},
    shipmentStatusDescriptions = {},
    temperatureStatusDescriptions = {},
): AvailableFilterOptions => {
    const options = getFilterOptions(shipments);

    const shipmentStatus = fetchShipmentStatusOptions(
        shipments, options.shipmentStatusArr, shipmentStatusLabels, shipmentStatusDescriptions,
    );
    const temperatureStatus = fetchTemperatureStatusOptions(
        shipments, options.temperatureStatusArr, temperatureStatusLabels, temperatureStatusDescriptions,
    );
    const allPackagingCount = temperatureStatus.reduce((sum, option) => {
        return sum + option.count;
    }, 0);

    const { destinationAirportsArr = [], originAirportsArr = [] } = options;

    return {
        allPackagingCount,
        allShipmentCount: shipments.length,
        destinationAirports: destinationAirportsArr.sort(),
        originAirports: originAirportsArr.sort(),
        shipmentStatus,
        temperatureStatus,
    };
};

export const getShipmentStatusCount = (shipments: GenericShipmentData[]): { [key: string]: number } => {
    const statuses = shipments.map(({ status }) => status);
    const availableStatuses = [...(new Set(statuses))];

    return availableStatuses.reduce((data, option) => {
        return {
            ...data,
            [option]: statuses.filter(status => status === option).length,
        };
    }, {});
};

export interface ClientSideFilter {
    destinationAirports: string[],
    originAirports: string[],
    shipmentStatus: string[],
    temperatureStatus: string[]
}

export const initialShipmentFilterOptions: { [optionsGroupKey: string]: string[] } = {
    destinationAirports: [],
    energyLevelStatuses: [],
    originAirports: [],
    packagingTypeCodes: [],
    statuses: ['IN_TRANSIT', 'NOT_STARTED'],
    temperatureStatuses: [],
};

export interface FilterOptionsCount {
    shipmentStatusCount: { [key: string]: number },
    temperatureStatusCount: { [key: string]: number },
}

export const getPackagingsCount = (shipments: GenericShipmentData[] = []) : { [key: string]: number } => {
    const options = getFilterOptions(shipments);

    const temperatureStatus = fetchTemperatureStatusOptions(shipments, options.temperatureStatusArr);

    return temperatureStatus.reduce((data, option) => {
        return {
            ...data,
            [option.value]: option.count,
        };
    }, {});
};

export const initialFilterOptionsCount: FilterOptionsCount = {
    shipmentStatusCount: {},
    temperatureStatusCount: {},
};

const areCoordinatesEqual = (point1: LatLng, point2: LatLng): boolean => {
    return point1.lat === point2.lat && point1.lng === point2.lng;
};

export interface ClusterInfo {
    externalIds: string[],
    hasBreach: boolean,
    hasPending: boolean,
    hasPotentialWarning: boolean,
    hasWarning: boolean,
    id: number,
    position: LatLng
}

export const getClustersInfo = (shipments: GenericShipmentData[] = []): ClusterInfo[] => shipments
    .reduce((data: ClusterInfo[], shipment) => {
        if (!shipment.currentPosition) {
            return data;
        }

        const clusterIndexWithSameLocation = data
            .findIndex(cluster => areCoordinatesEqual(cluster.position, shipment.currentPosition));

        return clusterIndexWithSameLocation === -1
            ? [
                ...data,
                {
                    externalIds: [shipment.externalId],
                    hasBreach: shipment.isBreach,
                    hasPending: shipment.isPending,
                    hasPotentialWarning: shipment.isPotentialWarning,
                    hasWarning: shipment.isWarning,
                    id: data.length,
                    position: shipment.currentPosition,
                },
            ] : [
                ...data.slice(0, clusterIndexWithSameLocation),
                {
                    externalIds: [
                        ...data[clusterIndexWithSameLocation].externalIds,
                        shipment.externalId,
                    ],
                    hasBreach: data[clusterIndexWithSameLocation].hasBreach || shipment.isBreach,
                    hasPending: data[clusterIndexWithSameLocation].hasPending || shipment.isPending,
                    hasPotentialWarning: data[clusterIndexWithSameLocation].hasPotentialWarning
                        || shipment.isPotentialWarning,
                    hasWarning: data[clusterIndexWithSameLocation].hasWarning || shipment.isWarning,
                    id: data[clusterIndexWithSameLocation].id,
                    position: data[clusterIndexWithSameLocation].position,
                },
                ...data.slice(clusterIndexWithSameLocation + 1),
            ];
    }, []);

export const getFilteredShipmentsByAirports = (
    data: GenericShipmentData[] = [],
    filters: ClientSideFilter,
) => {
    return data.filter((shipment) => {
        return filters.originAirports.includes(shipment.originAirport)
            && filters.destinationAirports.includes(shipment.destinationAirport);
    });
};

export const getFilteredShipmentsByShipmentStatus = (
    data: GenericShipmentData[] = [],
    filters: ClientSideFilter,
) => {
    const { shipmentStatus } = filters;

    return data.filter((shipment) => shipmentStatus.includes(shipment.status));
};

export const getFilteredShipmentsByTemperatureStatus = (
    data: GenericShipmentData[] = [],
    filters: ClientSideFilter,
) => {
    const { temperatureStatus } = filters;

    return data.filter((shipment) => {
        return (shipment?.packagings?.length === 0
            ? true
            : shipment?.packagings?.some(item => {
                const { temperatureCheckResult } = item;

                return temperatureStatus.includes(temperatureCheckResult.temperatureStatus);
            }));
    });
};
